import React from "react";
import {observer} from "mobx-react";
import {Button, Typography} from "@mui/material";
import styled from "@emotion/styled";
import {useViewController} from "data/services/locator";
import type {IModalErrorController} from "views/components/modals/modal_error/modal_error.controller";
import {Bindings} from "data/constants/bindings";
import ErrorIcon from "@mui/icons-material/Error";
import CloseIcon from "@mui/icons-material/Close";
import {Exist} from "views/components/exist/exist.component";
import {ModalText, ModalContainer, InnerModal, ModalContent} from "views/components/modals/styles";

const CloseBlock = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

const Icon = styled.div`
	width: 80px;

	svg {
		width: 100%;
		height: auto;
		fill: #c20000;
	}
`;

export const ModalError: React.FC = observer(() => {
	const {isOpen, modalContent, i18n, close} = useViewController<IModalErrorController>(
		Bindings.ModalErrorController
	);

	if (!isOpen) {
		return null;
	}

	return (
		<ModalContainer>
			<InnerModal>
				<ModalContent>
					<CloseBlock>
						<Button sx={{minWidth: 0}} onClick={close}>
							<CloseIcon />
						</Button>
					</CloseBlock>
					<Icon>
						<ErrorIcon />
					</Icon>
					<ModalText>
						<Typography variant={"h4"}>
							{i18n.t(modalContent?.title ?? "modal.error.title", "Error")}
						</Typography>
						<Exist when={modalContent?.message}>
							<p>{modalContent?.message}</p>
						</Exist>
					</ModalText>
				</ModalContent>
			</InnerModal>
		</ModalContainer>
	);
});
