import {inject, injectable} from "inversify";
import type {IHttpClientService, IApiResponse} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import {IDashboard} from "data/types/dashboard";

export interface IDashboardApiProvider {
	get: () => Promise<AxiosResponse<IApiResponse<IDashboard>>>;
}

@injectable()
export class DashboardApiProvider implements IDashboardApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	get = () => this._http.get<IApiResponse<IDashboard>>(`predictor/dashboard/stats`);
}
