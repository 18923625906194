import "reflect-metadata";

import "assets/css/reset.css";
import "assets/fonts/stylesheet.css";
import "assets/fonts/bebas/stylesheet.css";
import "assets/css/core.css";

import React, {Suspense, lazy} from "react";
import reportWebVitals from "./reportWebVitals";
import {RootRoutes} from "routes";
import {init, Integrations, BrowserTracing, Replay} from "@sentry/react";
import {
	createBrowserRouter,
	RouterProvider,
	createRoutesFromElements,
	Route,
	Outlet,
} from "react-router-dom";
import {createRoot} from "react-dom/client";
import {
	DIContainer,
	services,
	providers,
	stores,
	controllers,
} from "data/services/locator/locator_container.service";
import {InjectionProvider} from "data/services/locator/locator_provider.service";
import {SENTRY_DSN_URL, BASE_URL} from "data/constants";
import {PagePreloader} from "views/components/preloader";
import {SecretGateController} from "views/controllers/secrete_gate/secret_gate.controller";
import {Session} from "views/components/session/session.component";
import {Bootstrap} from "views/components/bootstrap/bootstrap.component";
import {ThemeProvider, CssBaseline} from "@mui/material";
import {theme} from "assets/theming/theme";
import {retryFailLoad} from "data/utils";
import {Modals} from "views/components/modals";

const NotFound = lazy(retryFailLoad(() => import("views/pages/not_found/not_found.page")));

if (SENTRY_DSN_URL) {
	init({
		dsn: SENTRY_DSN_URL,
		integrations: [
			new BrowserTracing(),
			new Replay(),
			new Integrations.Breadcrumbs({
				console: false,
			}),
		],
		environment: process.env.REACT_APP_SENTRY_ENV || "development",
		allowUrls: [
			"*.f2p.media.geniussports.com",
			// TODO Add Prod domain before first release
		],
		denyUrls: [
			"quantcast",
			"xsca",
			// browser's extensions
			/extensions\//i,
			/^chrome:\/\//i,
			/^moz-extension:\/\//i,
		],
		sampleRate: 0.1,
	});
}

const root = document.getElementById("root");

if (!root) {
	throw Error("Root element was not found");
}

DIContainer.load(services, providers, stores, controllers);

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route
			path={"/"}
			element={
				<Suspense fallback={<PagePreloader />}>
					<Outlet />
				</Suspense>
			}>
			{SecretGateController.IS_SECRET_PASSED ? (
				RootRoutes
			) : (
				<Route path="*" element={<NotFound />} />
			)}
		</Route>
	),
	{
		basename: BASE_URL,
	}
);

createRoot(root).render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<InjectionProvider container={DIContainer}>
				<Bootstrap>
					<Session>
						<RouterProvider router={router} />
					</Session>
					<Modals />
				</Bootstrap>
			</InjectionProvider>
		</ThemeProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
