/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-unused-vars */

/*
 * Disabled the following rules:
 * @typescript-eslint/naming-convention
 * @typescript-eslint/no-empty-interface
 * @typescript-eslint/no-unused-vars
 * as MUI theme override required override interfaces
 */
import {ThemeOptions} from "@mui/material/styles";

declare module "@mui/material/styles" {
	interface Palette {
		input: {
			main: string;
			hover: string;
		};
		backgrounds: {
			first: "#007A35";
			second: "#314C9E";
			third: "#ECBE1A";
			forth: "#7986cb";
			fifth: "#0c0c45";
			sixth: "#6977aa";
			seventh: "#ec8b1a";
			eighth: "#173282";
			ninth: "#fff";
		};
		colors: {
			first: string;
			second: string;
			third: string;
			forth: string;
			fifth: string;
		};
	}

	interface PaletteOptions {
		input: {
			main: string;
			hover: string;
		};
		backgrounds: {
			first: "#007A35";
			second: "#314C9E";
			third: "#ECBE1A";
			forth: "#7986cb";
			fifth: "#0c0c45";
			sixth: "#6977aa";
			seventh: "#ec8b1a";
			eighth: "#173282";
			ninth: "#fff";
		};
		colors: {
			first: string;
			second: string;
			third: string;
			forth: string;
			fifth: string;
		};
	}
}

export const paletteTheme: ThemeOptions = {
	palette: {
		input: {
			hover: "#ECBE1A",
			main: "#fff",
		},
		backgrounds: {
			first: "#007A35",
			second: "#314C9E",
			third: "#ECBE1A",
			forth: "#7986cb",
			fifth: "#0c0c45",
			sixth: "#6977aa",
			seventh: "#ec8b1a",
			eighth: "#173282",
			ninth: "#fff",
		},
		colors: {
			first: "#fff",
			second: "#0c0c45",
			third: "#ecbe1a",
			forth: "#173282",
			fifth: "#005E29",
		},
		primary: {
			main: "#ECBE1A",
		},
		error: {
			main: "#DC2C2C",
		},
		secondary: {
			main: "#0C0C45",
		},
		text: {
			primary: "#fff",
			secondary: "#fff",
		},
		background: {
			default: "#0C0E42",
			paper: "#173282",
		},
	},
};
