import React, {Fragment} from "react";
import {ModalError} from "views/components/modals/modal_error/modal_error.component";
import {RegistrationModal} from "views/components/modals/registration_modal/registration_modal.component";

export const Modals: React.FC = () => {
	return (
		<Fragment>
			<ModalError />
			<RegistrationModal />
		</Fragment>
	);
};
