import {action, makeAutoObservable, observable} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IJSONProvider} from "data/providers/json/json.provider";
import {reduce} from "lodash";

export type IChecksums = Record<string, string>;

export interface IChecksumStore {
	get checksums(): IChecksums;

	get changedChecksums(): IChecksums;

	fetchChecksums(): Promise<void>;
}

@injectable()
export class ChecksumStore implements IChecksumStore {
	constructor(@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider) {
		makeAutoObservable(this);
	}

	@observable protected _checksums: IChecksums = {};

	get checksums() {
		return this._checksums;
	}

	@observable _changedChecksums: IChecksums = {};

	get changedChecksums() {
		return this._changedChecksums;
	}

	@action
	async fetchChecksums(): Promise<void> {
		try {
			const {data} = await this._jsonProvider.checksums();
			this._checksums = data;
		} catch (e) {
			console.error("Error while fetching checksums");
		}
	}

	@action
	protected compareChecksums(checksums: IChecksums): void {
		this._changedChecksums = reduce(
			checksums,
			(acc, checksum, key) => {
				const oldChecksum = this._checksums[key];
				return checksum !== oldChecksum ? {...acc, [key]: checksum} : acc;
			},
			{}
		);

		this._checksums = checksums;
	}
}
