import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Locale} from "data/enums";
import {Bindings} from "data/constants/bindings";
import {makeAutoObservable, runInAction} from "mobx";
import type {IContestStore} from "data/stores/contest/contest.store";
import type {ICompetitionsStore} from "data/stores/competitions/competitions.store";
import type {IHttpClientService} from "data/services/http";

export interface IBootstrapController extends ViewController {
	get isReady(): boolean;
}

@injectable()
export class BootstrapController implements IBootstrapController {
	private _userLocale: Locale = Locale.PT_BR;

	constructor(
		@inject(Bindings.LocalizationStore) private _i18nStore: ILocalizationStore,
		@inject(Bindings.ApiHTTPClient) private _apiHTTPClient: IHttpClientService,
		@inject(Bindings.ContestStore) private _contestStore: IContestStore,
		@inject(Bindings.CompetitionsStore) private _competitionsStore: ICompetitionsStore
	) {
		makeAutoObservable(this);
	}

	private _isReady = false;

	get isReady(): boolean {
		return this._isReady;
	}

	dispose(): void {
		return;
	}

	async init() {
		try {
			await this._competitionsStore.fetch();
			await this._contestStore.fetch();
			await this._i18nStore.switchLocale({
				locale: this.defineLocale(),
			});

			// Set locale that will be appended to each request
		} catch (_err) {
			// Show error message to a user
		}

		this._apiHTTPClient.setLocale(this._i18nStore.lang);
		runInAction(() => {
			this._isReady = true;
		});
	}

	/**
	 * The method is to define a user's locale. It can be done by:
	 * 1) navigator.language
	 * 2) Site URL
	 * 3) Some JSON or API request settings
	 * 4) Whatever else
	 */
	private defineLocale(): Locale {
		const url = new URLSearchParams(window.location.search);
		const localeFromURL =
			Number(url.get("change_lang") ?? 1) === 1 ? Locale.EN_US : (Locale.PT_BR as Locale);
		const isLocaleAllowed = [Locale.EN_US, Locale.PT_BR].some((it) =>
			it.toLowerCase().includes(localeFromURL.toLowerCase())
		);

		if (isLocaleAllowed && localeFromURL.length) {
			this._userLocale = localeFromURL;
		}

		return this._userLocale;
	}
}
