import React from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {type IRegistrationModalController} from "views/components/modals/registration_modal/registration_modal.controller";
import TextField from "@mui/material/TextField";
import {Checkbox, FormControlLabel, Button, Typography} from "@mui/material";
import {
	InnerModal,
	ModalContent,
	ModalContainer,
	ModalText,
	CloseButton,
} from "views/components/modals/styles";
import styled from "@emotion/styled";
import {Exist} from "views/components/exist/exist.component";
import IconButton from "@mui/material/IconButton";
import {CloseRounded} from "@mui/icons-material";

const Form = styled.form`
	display: flex;
	flex-flow: column;
	padding: 0 20px;
	width: 100%;
	max-width: 370px;
	gap: 16px;
`;
const TextFieldStyled = styled(TextField)`
	--inputColor: #0c0c45;
	--borderColor: #6977aa;

	&.MuiTextField-root {
		width: 100%;
	}

	.MuiFormLabel-root {
		color: var(--inputColor);
	}

	.MuiOutlinedInput-root {
		color: var(--inputColor);

		&:hover {
			.MuiOutlinedInput-notchedOutline {
				border-color: var(--borderColor);
			}
		}
	}

	.MuiInputBase-root .MuiOutlinedInput-notchedOutline {
		border-color: var(--borderColor);

		&:hover {
			border-color: var(--borderColor);
		}

		.MuiOutlinedInput-root & {
			&:hover {
				border-color: var(--borderColor);
			}
		}
	}

	.Mui-focused .MuiOutlinedInput-notchedOutline {
		border-color: var(--borderColor);
	}

	.MuiFormHelperText-root {
	}
`;

const Logo = styled.div`
	width: 150px;
	height: 50px;
	background: #f2f2f2;
`;
const ErrorMessage = styled(Typography)`
	color: var(--invalidColor);
	text-align: center;
`;

export const RegistrationModal: React.FC = observer(() => {
	const {i18n, submitForm, isOpen, errorMessage, closeModal} =
		useViewController<IRegistrationModalController>(Bindings.RegistrationModalController);

	if (!isOpen) {
		return null;
	}

	return (
		<ModalContainer>
			<InnerModal>
				<ModalContent>
					<CloseButton>
						<IconButton onClick={closeModal}>
							<CloseRounded />
						</IconButton>
					</CloseButton>
					<Logo />
					<ModalText>
						<Typography variant={"h4"}>
							{i18n.t("modals.registration.title", "Welcome to the game")}
						</Typography>
						<p>
							{i18n.t(
								"modals.registration.description",
								"Create your display name and let’s get started"
							)}
						</p>
					</ModalText>

					<Form
						onSubmit={(
							e: React.FormEvent<
								{
									username: HTMLInputElement;
									terms: HTMLInputElement;
								} & HTMLFormElement
							>
						) => {
							e.preventDefault();
							e.stopPropagation();
							void submitForm({
								username: e.currentTarget.username.value,
								terms: e.currentTarget.terms.checked,
							});
						}}>
						<TextFieldStyled
							required={true}
							label={i18n.t("registration.form.name_label", "Displayname")}
							name={"username"}
							variant={"outlined"}
							color={"secondary"}
							helperText={errorMessage}
						/>
						<FormControlLabel
							control={
								<Checkbox
									required={true}
									name={"terms"}
									value={true}
									color={"secondary"}
								/>
							}
							label={i18n.t(
								"registration.form.terms",
								`I agree to the Terms and Conditions`
							)}
							componentsProps={{
								typography: {
									variant: "caption",
								},
							}}
						/>

						<Exist when={!!errorMessage.length}>
							<ErrorMessage variant={"body2Black"}>{errorMessage}</ErrorMessage>
						</Exist>

						<Button
							variant={"contained"}
							size={"large"}
							type={"submit"}
							disableElevation={true}>
							{i18n.t("registration.form.button", "Get Picking")}
						</Button>
					</Form>
				</ModalContent>
			</InnerModal>
		</ModalContainer>
	);
});
