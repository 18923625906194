import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {injectable, inject} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IUserStore} from "data/stores/user/user.store";

export interface ILogoutController extends ViewController {
	logout: () => Promise<void>;
}

@injectable()
export class LogoutController implements ILogoutController {
	constructor(@inject(Bindings.UserStore) private _userStore: IUserStore) {
		makeAutoObservable(this);
	}

	async logout() {
		await this._userStore.logout();
		sessionStorage.clear();
	}

	init(param: void) {
		void this.logout();
	}
}
