import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {injectable, inject} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IUserStore} from "data/stores/user/user.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";
import {AxiosError} from "axios";
import {FrameBridge} from "data/utils/frame_bridge";
import {extractErrorMessage} from "data/utils";
import {IApiResponse} from "data/services/http";

export interface IRegistrationFields {
	terms: boolean;
	username: string;
}

export interface IRegistrationModalController extends ViewController {
	i18n: ILocalizationStore;

	get isOpen(): boolean;
	get errorMessage(): string;

	closeModal: () => void;
	submitForm: (params: IRegistrationFields) => Promise<void>;
}

@injectable()
export class RegistrationModalController implements IRegistrationModalController {
	@observable _error: string = "";

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.UserStore) public _userStore: IUserStore,
		@inject(Bindings.ModalsStore) public _modalStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return this._modalStore.modal === ModalType.REGISTER;
	}

	get errorMessage(): string {
		return this._error;
	}

	closeModal = () => {
		this._modalStore.hideModal();
	};

	submitForm = async (params: IRegistrationFields) => {
		try {
			await this._userStore.registerJWT({
				...params,
			});

			this._modalStore.hideModal();
			FrameBridge.CLEAR();
		} catch (e) {
			this._error = extractErrorMessage(e as AxiosError<IApiResponse>);
		}
	};
}
