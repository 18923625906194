import {ThemeOptions} from "@mui/material/styles/createTheme";
import {css} from "@mui/material/styles";

export const textFieldTheme: ThemeOptions = {
	components: {
		MuiSelect: {
			styleOverrides: {
				icon: {
					color: "#fff",
				},
				outlined: {
					color: "#fff",
				},
			},
		},

		MuiCheckbox: {
			styleOverrides: {
				colorSecondary: (props) => css`
					color: ${props.theme.palette.colors.second};
					font-size: 12px;
				`,
			},
		},
	},
};
