import {Container, ContainerModule, type interfaces} from "inversify";
import {Bindings} from "data/constants/bindings";
import {type IUserStore, UserStore} from "data/stores/user/user.store";
import {AuthController, type IAuthController} from "views/controllers/auth/auth.controller";
import {
	type IBackdoorController,
	BackdoorController,
} from "views/pages/backdoor/backdoor.controller";
import {API_URL, CONTENT_JSON_URL, JSON_URL} from "data/constants";
import {type IJSONProvider, JSONProvider} from "data/providers/json/json.provider";
import {AuthApiProvider, type IAuthApiProvider} from "data/providers/api/auth.api.provider";
import {HttpClientFactory, type IHttpClientService} from "data/services/http";
import {CountriesStore, type ICountriesStore} from "data/stores/countries/countries.store";
import {RoundsStore, type IRoundsStore} from "data/stores/rounds/rounds.store";
import {PlayersStore, type IPlayersStore} from "data/stores/players/players.store";
import {SquadsStore, type ISquadsStore} from "data/stores/squads/squds.store";
import {
	type ISecretGateController,
	SecretGateController,
} from "views/controllers/secrete_gate/secret_gate.controller";
import {
	type ILocalizationStore,
	LocalizationStore,
} from "data/stores/localization/localization.store";
import {
	type IStaticContentStore,
	StaticContentStore,
} from "data/stores/static_content/static_content.store";
import {HelpController, type IHelpController} from "views/pages/help/help.controller";
import {
	HelpListController,
	type IHelpListController,
} from "views/components/help/help_list/help_list.controller";
import {
	ContactUsController,
	type IContactUsController,
} from "views/components/help/contact_us/contact_us.controller";
import {
	type ILiveScoreController,
	LiveScoreController,
} from "views/controllers/live_score/live_score.controller";
import {ChecksumStore, type IChecksumStore} from "data/stores/checksum/checksum.store";
import {type IUserApiProvider, UserApiProvider} from "data/providers/api/user.api.provider";
import {
	type IPasswordApiProvider,
	PasswordApiProvider,
} from "data/providers/api/password.api.provider";
import {
	type ISessionController,
	SessionController,
} from "views/components/session/session.controller";
import {
	BootstrapController,
	type IBootstrapController,
} from "views/components/bootstrap/bootstrap.controller";
import {IHeaderController, HeaderController} from "views/components/header/header.controller";
import {
	LocalizationController,
	ILocalizationController,
} from "views/controllers/localization/localization.controller";
import {
	ContestListController,
	IContestListController,
} from "views/pages/contest_list/contest_list.controller";
import {type IContestStore, ContestStore} from "data/stores/contest/contest.store";
import {
	QuestionsController,
	IQuestionsController,
} from "views/pages/questions/questions.controller";
import {AnswersApiProvider, IAnswersApiProvider} from "data/providers/api/answers.api.provider";
import {IAnswersStore, AnswersStore} from "data/stores/answers/answers.store";
import {IGameBarController, GameBarController} from "views/components/game_bar/game_bar.controller";
import {
	IDashboardApiProvider,
	DashboardApiProvider,
} from "data/providers/api/dashboard.api.provider";
import {DashboardStore, IDashboardStore} from "data/stores/dashboard/dashboard.store";
import {
	type IContestItemController,
	ContestItemController,
} from "views/components/contest_item/contest_item.controller";

import {
	type IContestCountdownController,
	ContestCountdownController,
} from "views/components/contest_countdown/contest_countdown.controller";
import {
	type IContestLockStatusController,
	ContestLockStatusController,
} from "views/components/contest_lock_status/contest_lock_status.controller";
import {
	type IHocSliderController,
	HocSliderController,
} from "views/components/hoc_slider/hoc_slider.controller";
import {
	IQuestionController,
	QuestionController,
} from "views/components/questions/question.controller";
import {
	type ICompetitionsStore,
	CompetitionsStore,
} from "data/stores/competitions/competitions.store";
import {
	type IContestResultListController,
	ContestResultListController,
} from "views/pages/contest_result_list/contest_result_list.controller";
import {type IUserStatsStore, UserStatsStore} from "data/stores/user_stats/user_stats.store";
import {type IUserStatsProvider, UserStatsProvider} from "data/providers/api/user_stats.provider";
import {
	type ILeaderboardController,
	LeaderboardController,
} from "views/components/leaderboard/leaderboard.controller";
import {type ILeaderboardStore, LeaderboardStore} from "data/stores/leaderboard/leaderboard.store";
import {
	type ILeaderboardProvider,
	LeaderboardProvider,
} from "data/providers/leaderboard/leaderboard.provider";
import {
	OverallLeaderboardController,
	IOverallLeaderboardController,
} from "views/components/leaderboard/overall_leaderboard/overall_leaderboard.controller";
import {
	ContestLeaderboardController,
	IContestLeaderboardController,
} from "views/components/leaderboard/contest_leaderboard/contest_leaderboard.controller";
import {
	type IRegistrationModalController,
	RegistrationModalController,
} from "views/components/modals/registration_modal/registration_modal.controller";
import {ModalsStore, IModalsStore} from "data/stores/modals/modals.store";
import {
	ModalErrorController,
	IModalErrorController,
} from "views/components/modals/modal_error/modal_error.controller";
import {type ILogoutController, LogoutController} from "views/pages/logout/logout.controller";

export const DIContainer = new Container();

export const services = new ContainerModule((bind: interfaces.Bind) => {
	bind<IHttpClientService>(Bindings.ApiHTTPClient).toConstantValue(
		HttpClientFactory.createApiClient({
			baseURL: API_URL,
			withCredentials: true,
		})
	);

	bind<IHttpClientService>(Bindings.JsonHTTPClient).toConstantValue(
		HttpClientFactory.createJSONClient({
			baseURL: JSON_URL,
		})
	);

	bind<IHttpClientService>(Bindings.ContentJsonHTTPClient).toConstantValue(
		HttpClientFactory.createJSONClient({
			baseURL: CONTENT_JSON_URL,
		})
	);
});

export const providers = new ContainerModule((bind: interfaces.Bind) => {
	bind<IJSONProvider>(Bindings.JSONProvider).to(JSONProvider);
	bind<IAuthApiProvider>(Bindings.AuthApiProvider).to(AuthApiProvider);
	bind<IUserApiProvider>(Bindings.UserApiProvider).to(UserApiProvider);
	bind<IPasswordApiProvider>(Bindings.PasswordApiProvider).to(PasswordApiProvider);
	bind<IAnswersApiProvider>(Bindings.AnswersApiProvider).to(AnswersApiProvider);
	bind<IDashboardApiProvider>(Bindings.DashboardApiProvider).to(DashboardApiProvider);
	bind<IUserStatsProvider>(Bindings.UserStatsProvider).to(UserStatsProvider);
	bind<ILeaderboardProvider>(Bindings.LeaderboardProvider).to(LeaderboardProvider);
});

export const stores = new ContainerModule((bind: interfaces.Bind) => {
	bind<IModalsStore>(Bindings.ModalsStore).to(ModalsStore).inSingletonScope();
	bind<IContestStore>(Bindings.ContestStore).to(ContestStore).inSingletonScope();
	bind<ILocalizationStore>(Bindings.LocalizationStore).to(LocalizationStore).inSingletonScope();
	bind<IUserStore>(Bindings.UserStore).to(UserStore).inSingletonScope();
	bind<IRoundsStore>(Bindings.RoundsStore).to(RoundsStore).inSingletonScope();
	bind<IPlayersStore>(Bindings.PlayersStore).to(PlayersStore).inSingletonScope();
	bind<ISquadsStore>(Bindings.SquadsStore).to(SquadsStore).inSingletonScope();
	bind<IAnswersStore>(Bindings.AnswersStore).to(AnswersStore).inSingletonScope();
	bind<IDashboardStore>(Bindings.DashboardStore).to(DashboardStore).inSingletonScope();

	bind<ICountriesStore>(Bindings.CountriesStore).to(CountriesStore).inSingletonScope();
	bind<IStaticContentStore>(Bindings.StaticContentStore)
		.to(StaticContentStore)
		.inSingletonScope();

	bind<IChecksumStore>(Bindings.ChecksumStore).to(ChecksumStore);
	bind<ICompetitionsStore>(Bindings.CompetitionsStore).to(CompetitionsStore).inSingletonScope();
	bind<IUserStatsStore>(Bindings.UserStatsStore).to(UserStatsStore).inSingletonScope();
	bind<ILeaderboardStore>(Bindings.LeaderboardStore).to(LeaderboardStore).inSingletonScope();
});

export const controllers = new ContainerModule((bind: interfaces.Bind) => {
	bind<IAuthController>(Bindings.AuthController).to(AuthController);
	bind<IBackdoorController>(Bindings.BackDoorController).to(BackdoorController);
	bind<ILocalizationController>(Bindings.LocalizationController).to(LocalizationController);
	bind<ISecretGateController>(Bindings.SecretGateController).to(SecretGateController);
	bind<IModalErrorController>(Bindings.ModalErrorController).to(ModalErrorController);
	bind<IHelpController>(Bindings.HelpController).to(HelpController);
	bind<IHelpListController>(Bindings.HelpListController).to(HelpListController);
	bind<IContactUsController>(Bindings.ContactUsController).to(ContactUsController);
	bind<ILiveScoreController>(Bindings.LiveScoreController).to(LiveScoreController);
	bind<ISessionController>(Bindings.SessionController).to(SessionController);
	bind<IBootstrapController>(Bindings.BootstrapController).to(BootstrapController);

	bind<IHeaderController>(Bindings.HeaderController).to(HeaderController);
	bind<IContestListController>(Bindings.ContestController).to(ContestListController);
	bind<IQuestionsController>(Bindings.QuestionsController).to(QuestionsController);
	bind<IQuestionController>(Bindings.QuestionController).to(QuestionController);
	bind<IGameBarController>(Bindings.GameBarController).to(GameBarController);
	bind<IContestItemController>(Bindings.ContestItemController).to(ContestItemController);
	bind<IContestCountdownController>(Bindings.ContestCountdownController).to(
		ContestCountdownController
	);
	bind<IContestLockStatusController>(Bindings.ContestLockStatusController).to(
		ContestLockStatusController
	);
	bind<IHocSliderController>(Bindings.HocSliderController).to(HocSliderController);
	bind<IContestResultListController>(Bindings.ContestResultListController).to(
		ContestResultListController
	);
	bind<ILeaderboardController>(Bindings.LeaderboardController).to(LeaderboardController);
	bind<IOverallLeaderboardController>(Bindings.OverallLeaderboardController).to(
		OverallLeaderboardController
	);
	bind<IContestLeaderboardController>(Bindings.ContestLeaderboardController).to(
		ContestLeaderboardController
	);
	bind<IRegistrationModalController>(Bindings.RegistrationModalController).to(
		RegistrationModalController
	);
	bind<ILogoutController>(Bindings.LogoutController).to(LogoutController);
});
