import {makeAutoObservable, observable, runInAction} from "mobx";
import {injectable, inject} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IDashboardApiProvider} from "data/providers/api/dashboard.api.provider";
import type {IDashboard} from "data/types/dashboard";

export interface IDashboardStore {
	get stats(): IDashboard;

	fetch(): Promise<IDashboard>;

	clear(): void;
}

const defaultDashboard = {
	overallPoints: null,
	overallRank: null,
	lastContestPoints: null,
	lastContestRank: null,
};

@injectable()
export class DashboardStore implements IDashboardStore {
	constructor(
		@inject(Bindings.DashboardApiProvider) private _dashboardProvider: IDashboardApiProvider
	) {
		makeAutoObservable(this);
	}

	@observable private _stats: IDashboard = defaultDashboard;

	get stats() {
		return this._stats;
	}

	set stats(list: IDashboard) {
		this._stats = list;
	}

	clear() {
		this._stats = defaultDashboard;
	}

	async fetch(): Promise<IDashboard> {
		const response = await this._dashboardProvider.get();

		if (response.data.success) {
			runInAction(() => {
				this._stats = response.data.success;
			});
		}

		return response.data.success;
	}
}
