import {makeAutoObservable, observable, runInAction} from "mobx";
import {injectable, inject} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ILeaderboardItem} from "data/types/leaderboard";
import {RequestState, ModalType} from "data/enums";
import type {ILeaderboardStore} from "data/stores/leaderboard/leaderboard.store";
import {ICommonLeaderboardController} from "views/components/leaderboard/leaderboard.controller";
import {AxiosError} from "axios";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {extractErrorMessage} from "data/utils";
import {IApiResponse} from "data/services/http";

type Params = Parameters<NonNullable<ICommonLeaderboardController["init"]>>[0];

export type IContestLeaderboardController = ICommonLeaderboardController;

@injectable()
export class ContestLeaderboardController implements IContestLeaderboardController {
	@observable private _contestId: number = 0;
	@observable private _requestState: RequestState = RequestState.IDLE;
	private _currentPage = 1;

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.LeaderboardStore) public _leaderboardStore: ILeaderboardStore,
		@inject(Bindings.ModalsStore) public _modalStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get isLoading(): boolean {
		return this._requestState === RequestState.PENDING;
	}

	get hasMore(): boolean {
		return this._leaderboardStore.leaderboard.nextPage;
	}

	get list(): ILeaderboardItem[] {
		return this._leaderboardStore.leaderboard.rankings;
	}

	get userId(): number {
		return this._leaderboardStore.leaderboard.userRanking?.userId || 0;
	}

	onLoadMore = async () => {
		this._requestState = RequestState.PENDING;
		this._currentPage = this._currentPage + 1;

		try {
			await this._leaderboardStore.fetchContestLoadMore({
				page: this._currentPage,
				lang: this.i18n.lang,
				contestId: this._contestId,
			});
			runInAction(() => {
				this._requestState = RequestState.SUCCESS;
			});
		} catch (e) {
			this._modalStore.showModal(ModalType.ERROR, {
				message: extractErrorMessage(e as AxiosError<IApiResponse>),
			});
			runInAction(() => {
				this._requestState = RequestState.ERROR;
			});
		}
	};

	async init(param: Params) {
		this._contestId = param.contestId;

		this._requestState = RequestState.PENDING;

		await this._fetchLeaderboard();
	}

	onChange(param: Params) {
		this._contestId = param.contestId;
		void this._fetchLeaderboard();
	}

	private async _fetchLeaderboard() {
		if (!this._contestId) {
			return;
		}
		this._requestState = RequestState.PENDING;

		try {
			await this._leaderboardStore.fetchContest({
				lang: this.i18n.lang,
				contestId: this._contestId,
			});
			runInAction(() => {
				this._requestState = RequestState.SUCCESS;
			});
		} catch (e) {
			runInAction(() => {
				this._requestState = RequestState.ERROR;
			});
		}
	}
}
