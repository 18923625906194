import {makeAutoObservable, observable, runInAction} from "mobx";
import {injectable, inject} from "inversify";
import type {IContest} from "data/types/contests";
import {Bindings} from "data/constants/bindings";
import type {IJSONProvider} from "data/providers/json/json.provider";
import {findLast} from "lodash";
import {ContestStatusEnum} from "data/enums";
import {QuestionUtils} from "data/utils/question";

type IITerribleType = IContest;

export interface IContestStore {
	get list(): IITerribleType[];

	get lastCompleted(): IITerribleType | undefined;
	get completedOrLiveContests(): IITerribleType[];

	getByID(id: number): IITerribleType | undefined;

	fetch(): Promise<IITerribleType[]>;

	safeFetch(): Promise<IITerribleType[]>;
}

@injectable()
export class ContestStore implements IContestStore {
	constructor(@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider) {
		makeAutoObservable(this);
	}

	@observable private _list: IITerribleType[] = [];

	get list() {
		return this._list;
	}

	set list(list: IITerribleType[]) {
		this._list = list;
	}

	get lastCompleted(): IITerribleType | undefined {
		return findLast(
			this._list,
			(it: IITerribleType) => it.status === ContestStatusEnum.COMPLETE
		);
	}

	get completedOrLiveContests() {
		return this.list.filter((contest) => {
			if (contest.status === ContestStatusEnum.COMPLETE) {
				return true;
			}

			if (contest.status === ContestStatusEnum.LIVE) {
				return QuestionUtils.IS_FIRST_COMPLETE(contest);
			}

			return false;
		});
	}

	getByID(id: number): IITerribleType | undefined {
		if (!id) {
			return;
		}
		return this._list.find((it) => {
			return it.id === id;
		});
	}

	async fetch(): Promise<IITerribleType[]> {
		const response = await this._jsonProvider.contests();

		if (response.data.contests) {
			runInAction(() => {
				this._list = response.data.contests;
			});
		}

		return response.data.contests;
	}

	async safeFetch(): Promise<IITerribleType[]> {
		if (!this._list.length) {
			return this.fetch();
		}

		return this._list;
	}
}
