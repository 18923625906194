import {injectable, inject} from "inversify";
import {AxiosResponse} from "axios";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import {Bindings} from "data/constants/bindings";
import type {ILeaderboard, IGetLeaderboard} from "data/types/leaderboard";

export interface ILeaderboardProvider {
	getOverall: (params: IGetLeaderboard) => Promise<AxiosResponse<IApiResponse<ILeaderboard>>>;
	getContest: (params: IGetLeaderboard) => Promise<AxiosResponse<IApiResponse<ILeaderboard>>>;
}

@injectable()
export class LeaderboardProvider implements ILeaderboardProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	getOverall = ({lang, contestId, ...params}: IGetLeaderboard) =>
		this._http.get<IApiResponse<ILeaderboard>>(
			`predictor/ranking/overall-leaderboard/${contestId}`,
			params
		);

	getContest = ({lang, contestId, ...params}: IGetLeaderboard) =>
		this._http.get<IApiResponse<ILeaderboard>>(
			`predictor/ranking/contest-leaderboard/${contestId}`,
			params
		);
}
