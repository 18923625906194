export enum RequestState {
	IDLE,
	PENDING,
	SUCCESS,
	ERROR,
}

export enum ModalType {
	ERROR,
	CONFIRM,
	REGISTER,
}

export enum SortOrder {
	ASC = "asc",
	DESC = "desc",
}

export enum RoundStatus {
	Scheduled = "scheduled",
	Playing = "playing",
	Complete = "complete",
}

export enum SocialNetwork {
	Facebook,
	Twitter,
	Mobile,
}

export enum ShareType {
	General = "general",
	League = "league",
}

export enum Locale {
	EN_US = "en-US",
	PT_BR = "pt-BR",
}

export enum Language {
	EN = "en",
	PR = "pt",
}

export enum MarketTypeEnum {
	EVENT_RESULT = "event_result",
	OVER_UNDER = "over_under",
	TOTAL_GOALS = "total_goals",
	HANDICAP = "handicap",
}

export enum ContestStatusEnum {
	LIVE = "live",
	COMPLETE = "complete",
	OPEN = "open",
}

export enum QuestionStatusEnum {
	LOCKED = "locked",
	COMPLETE = "complete",
	OPEN = "open",
}

export enum QuestionTypeEnum {
	MULTIPLE_CHOICE = "multiple_choice",
	TIEBREAKER = "tie_breaker",
}

export enum AnswerStatusEnum {
	CORRECT = "correct",
	IN_CORRECT = "in_correct",
	LOCKED = "locked",
	MISSED = "missed",
}
