import {makeAutoObservable, observable, runInAction} from "mobx";
import {injectable, inject} from "inversify";
import {Bindings} from "data/constants/bindings";
import {ICompetition} from "data/types/competitions";
import type {IJSONProvider} from "data/providers/json/json.provider";

type IITerribleType = ICompetition;

export interface ICompetitionsStore {
	get list(): IITerribleType[];

	getByID(id: number): IITerribleType | undefined;

	fetch(): Promise<IITerribleType[]>;

	safeFetch(): Promise<IITerribleType[]>;
}

@injectable()
export class CompetitionsStore implements ICompetitionsStore {
	constructor(@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider) {
		makeAutoObservable(this);
	}

	@observable private _list: IITerribleType[] = [];

	get list() {
		return this._list;
	}

	set list(list: IITerribleType[]) {
		this._list = list;
	}

	getByID(id: number): IITerribleType | undefined {
		if (!id) {
			return;
		}
		return this._list.find((it) => {
			return it.id === id;
		});
	}

	async fetch(): Promise<IITerribleType[]> {
		const response = await this._jsonProvider.competitions();

		if (response.data.competitions) {
			runInAction(() => {
				this._list = response.data.competitions;
			});
		}

		return response.data.competitions;
	}

	async safeFetch(): Promise<IITerribleType[]> {
		if (!this._list.length) {
			return this.fetch();
		}

		return this._list;
	}
}
