import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {
	IAuthApiProvider,
	ILoginPayload,
	IRegistrationJWTPayload,
} from "data/providers/api/auth.api.provider";
import type {IUserApiProvider} from "data/providers/api/user.api.provider";
import type {IPasswordApiProvider} from "data/providers/api/password.api.provider";
import {SessionUtils} from "data/utils/session";

export interface IUser {
	id: number;
	email: string;
	username: string;
	isNotificationsEnabled: boolean;
}

export interface IUserStore {
	get hasToken(): boolean;

	get user(): IUser | undefined;

	get isAuthorized(): boolean;

	get wasLoggedOut(): boolean;

	saveToken(token: string): void;

	loginJWT(): Promise<void>;

	registerJWT(payload: Omit<IRegistrationJWTPayload, "token">): Promise<void>;

	login(payload: ILoginPayload): Promise<void>;

	logout(): Promise<void>;

	getUser(): Promise<void>;
}

@injectable()
export class UserStore implements IUserStore {
	static REQUIRE_REGISTER_ERROR_CODE = 406;

	private _token: string = "";

	constructor(
		@inject(Bindings.AuthApiProvider) private _authApi: IAuthApiProvider,
		@inject(Bindings.UserApiProvider) private _userApi: IUserApiProvider,
		@inject(Bindings.PasswordApiProvider) private _passwordApi: IPasswordApiProvider
	) {
		makeAutoObservable(this);
	}

	@observable private _user?: IUser = undefined;

	saveToken(token: string) {
		this._token = token;
	}

	get token() {
		return this._token;
	}

	get hasToken(): boolean {
		return !!this.token.length;
	}

	get user() {
		return this._user;
	}

	@observable private _wasLoggedOut = false;

	get wasLoggedOut() {
		return this._wasLoggedOut;
	}

	get isAuthorized() {
		return Boolean(this.user);
	}

	@action
	async login(payload: ILoginPayload) {
		const response = await this._authApi.backdoor(payload);
		const {session} = response.data.success;

		runInAction(() => {
			this._user = session.user;
			this._wasLoggedOut = false;
		});

		SessionUtils.save(session.id);
	}

	@action
	async loginJWT(): Promise<void> {
		const response = await this._authApi.loginJWT({
			token: this.token,
		});
		const {session} = response.data.success;

		runInAction(() => {
			this._user = session.user;
			this._wasLoggedOut = false;
		});

		SessionUtils.save(session.id);
	}

	@action
	async registerJWT(payload: Omit<IRegistrationJWTPayload, "token">): Promise<void> {
		const response = await this._authApi.registerJWT({
			...payload,
			token: this.token,
		});

		const {session} = response.data.success;

		runInAction(() => {
			this._user = session.user;
			this._wasLoggedOut = false;
		});

		SessionUtils.save(session.id);
	}

	@action
	async logout() {
		await this._authApi.logout();

		runInAction(() => {
			this._user = undefined;
			this._wasLoggedOut = true;
		});

		SessionUtils.clear();
	}

	@action
	async getUser() {
		await this._authApi.getUser();

		const response = await this._authApi.getUser();

		runInAction(() => {
			this._user = {
				...response.data.success.user,
			};
			this._wasLoggedOut = false;
		});
	}
}
