import {inject, injectable} from "inversify";
import type {IHttpClientService, IApiResponse} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import {IAnswer, IAnswerPayload} from "data/types/contests";

export interface IAnswerRequestPayload {
	contestId: number;
}

export interface IAnswersPayload extends IAnswerRequestPayload {
	answers: IAnswerPayload[];
}

export interface IAnswersResponse {
	answers: IAnswer[];
}

export interface IAnswersApiProvider {
	save: (params: IAnswersPayload) => Promise<AxiosResponse<IApiResponse<IAnswersResponse>>>;
	get: (params: IAnswerRequestPayload) => Promise<AxiosResponse<IApiResponse<IAnswersResponse>>>;
}

@injectable()
export class AnswersApiProvider implements IAnswersApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	save = ({contestId, ...params}: IAnswersPayload) =>
		this._http.post<IApiResponse<IAnswersResponse>>(`predictor/answers/${contestId}`, params);

	get = ({contestId}: IAnswerRequestPayload) =>
		this._http.get<IApiResponse<IAnswersResponse>>(`predictor/answers/${contestId}`);
}
