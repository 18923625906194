import {ENV} from "data/constants";

export abstract class SessionUtils {
	static sessionStorageKey = `${ENV}-sessionId`;

	static save(sessionID: string = "") {
		sessionStorage.setItem(SessionUtils.sessionStorageKey, sessionID);
	}

	static get() {
		return sessionStorage.getItem(SessionUtils.sessionStorageKey);
	}

	static clear() {
		sessionStorage.removeItem(SessionUtils.sessionStorageKey);
	}
}
