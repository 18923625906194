import styled from "@emotion/styled";
import {WithTheme} from "data/types/generics";
import {Stack} from "@mui/material";

export const ModalContainer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overscroll-behavior: contain;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(0, 0, 0, 0.5);
	z-index: 99;
`;

export const InnerModal = styled.div`
	padding: 20px 20px 30px;
	background: #fff;
	width: 90%;
	max-width: 560px;
	border-radius: 6px;
`;

export const CloseButton = styled.div<WithTheme>`
	width: 100%;
	justify-content: flex-end;
	align-items: center;
	display: flex;
	svg {
		color: ${(props) => props.theme.palette.colors.second};
	}
`;

export const ModalContent = styled(Stack)<WithTheme>`
	align-items: center;
	gap: 22px;
	color: ${(props) => props.theme.palette.colors.second};
	img {
		max-width: 150px;
	}
`;

export const ModalText = styled(Stack)`
	gap: 12px;
	align-items: center;
	text-align: center;

	p {
		font-size: 16px;
		font-family: var(--fontFamilyBody);
		font-style: normal;
		font-weight: 400;
		line-height: 130%;
	}
`;
