import {makeAutoObservable, observable, runInAction} from "mobx";
import {injectable, inject} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IUserStatsProvider} from "data/providers/api/user_stats.provider";
import {IContestsUserStat} from "data/types/user_stats";

type IITerribleType = IContestsUserStat;

export interface IUserStatsStore {
	get list(): IITerribleType[];

	getByID(id: number): IITerribleType | undefined;

	fetch(): Promise<IITerribleType[]>;
}

@injectable()
export class UserStatsStore implements IUserStatsStore {
	constructor(
		@inject(Bindings.UserStatsProvider) private _userStatsProvider: IUserStatsProvider
	) {
		makeAutoObservable(this);
	}

	@observable private _list: IITerribleType[] = [];

	get list() {
		return this._list;
	}

	set list(list: IITerribleType[]) {
		this._list = list;
	}

	getByID(id: number): IITerribleType | undefined {
		if (!id) {
			return;
		}
		return this._list.find((it) => {
			return it.contestId === id;
		});
	}

	async fetch(): Promise<IITerribleType[]> {
		const response = await this._userStatsProvider.get();

		if (response.data.success.contestsStats) {
			runInAction(() => {
				this._list = response.data.success.contestsStats;
			});
		}

		return response.data.success.contestsStats;
	}
}
