import {addScript, parseStringToJson} from "data/utils/index";

interface ISelection {
	event_bet_selection_id: string;
	bt_cat_id: number | null;
}

export class FrameBridge {
	private static TOKEN: string = new URLSearchParams(window.location.search).get("token") ?? "";
	static ERROR: string | null = null;
	static client: IntegrationHandler | null = null;

	static CLEAR() {
		FrameBridge.TOKEN = "";
		FrameBridge.ERROR = "";
	}

	public static async listenToken(): Promise<string> {
		return new Promise<string>((resolve, reject) => {
			if (FrameBridge.TOKEN) {
				resolve(FrameBridge.TOKEN);
				return;
			}
			window.addEventListener(
				"message",
				(event: MessageEvent<{type: string; payload: string} | string>) => {
					if (!event.origin.includes("in2bet")) {
						return;
					}
					if (typeof event.data === "string") {
						FrameBridge.TOKEN = event.data;
						return;
					}
					if (event.data.type === "load") {
						const data = parseStringToJson<{send_path: string; token: string}>(
							event.data.payload
						);

						if (!data) {
							FrameBridge.ERROR = "Smth went wrong...";
							reject(FrameBridge.ERROR);
							return;
						}

						FrameBridge.TOKEN = data.token;
						const dataSendPath = data.send_path;

						if (dataSendPath) {
							void addScript(dataSendPath).then((script: HTMLScriptElement) => {
								window.IntegrationHandler = IntegrationHandler;
								FrameBridge.client = new window.IntegrationHandler(
									new URL(dataSendPath).origin,
									window.parent,
									{
										methods: {
											error: (error: unknown) => {
												console.log(error);
											},
											selections_added: (payload: unknown) => {
												console.log("Confirm of selections added", payload);
											},
											bet_placed: (payload: unknown) => {
												console.log("Confirm bet_placed", payload);
											},
										},
									}
								);
							});
						}

						resolve(data.token);
					}
				}
			);

			window.parent.postMessage({type: "gs_ready"}, "*");
		});
	}

	static setCallBacks(methods: Partial<IntegrationHandler["config"]["methods"]>) {
		const client = FrameBridge.client;
		if (client) {
			client.config.methods = {
				...client.config.methods,
				...methods,
			};
			client.setBehaviour();
		}
	}

	static sendMessage(data?: ISelection[]) {
		if (!data) {
			return;
		}
		console.log(`sendSelections with payload: ${JSON.stringify(data)}`);
		FrameBridge.client?.sendSelections(JSON.stringify(data));
	}
}
