import {AxiosRequestConfig} from "axios";
import {HttpClientService} from "data/services/http/http_client.service";
import {SessionUtils} from "data/utils/session";

export abstract class HttpClientFactory {
	static createApiClient(config: AxiosRequestConfig) {
		const client = new HttpClientService(config);
		// You can add interceptors here to adjust or modify any of requests/responses values.
		client.interceptors.request.use((it) => {
			const data = it.params as unknown as Record<string, string>;
			const sid = SessionUtils.get();

			if (sid) {
				return {
					...it,
					params: {
						...data,
						sid,
					},
				};
			}

			return it;
		});

		return client;
	}

	static createJSONClient(config: AxiosRequestConfig) {
		// You can add interceptors here to adjust or modify any of requests/responses values.
		return new HttpClientService(config);
	}
}
