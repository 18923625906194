import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {makeAutoObservable, observable, runInAction} from "mobx";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";
import {FrameBridge} from "data/utils/frame_bridge";
import {AxiosError} from "axios";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import {UserStore, type IUserStore} from "data/stores/user/user.store";
import {extractErrorMessage} from "data/utils";
import {IS_SSO_BACKDOOR} from "data/constants";

export interface ISessionController extends ViewController {
	get isSessionChecked(): boolean;
}

@injectable()
export class SessionController implements ISessionController {
	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LocalizationStore) private _i18nStore: ILocalizationStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.ApiHTTPClient) private _apiHTTPClient: IHttpClientService
	) {
		makeAutoObservable(this);
	}

	@observable _isSessionChecked = false;

	get isSessionChecked(): boolean {
		return this._isSessionChecked;
	}

	dispose(): void {
		return;
	}

	async init() {
		if (IS_SSO_BACKDOOR) {
			try {
				sessionStorage.setItem("backdoor", "true");
				await this._userStore.getUser();
			} catch (_e) {
				// Do nothing as the error is expected when a user isn't authorized
			} finally {
				runInAction(() => {
					this._isSessionChecked = true;
				});
			}

			return;
		}
		const token = await FrameBridge.listenToken();
		this._userStore.saveToken(token);

		try {
			await this._userStore.logout();
		} catch (e) {
			// Do nothing as the error is expected when a user isn't authorized
		}

		try {
			if (token) {
				await this._userStore.loginJWT();
			}
		} catch (e) {
			const error = e as AxiosError<IApiResponse>;

			if (UserStore.REQUIRE_REGISTER_ERROR_CODE === error.response?.status) {
				this._modalsStore.showModal(ModalType.REGISTER);
				return;
			}
			this._modalsStore.showModal(ModalType.ERROR, {
				message: extractErrorMessage(error),
			});
		} finally {
			runInAction(() => {
				this._isSessionChecked = true;
			});
		}
	}
}
