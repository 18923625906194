import React, {lazy, Fragment} from "react";
import {Route} from "react-router-dom";

import {retryFailLoad} from "data/utils";
import {NotAuthOnlyRoute, PrivateRoute} from "views/components/route";

const Backdoor = lazy(retryFailLoad(() => import("views/pages/backdoor/backdoor.page")));
const Help = lazy(retryFailLoad(() => import("views/pages/help/help.page")));
const Contests = lazy(retryFailLoad(() => import("views/pages/contest_list/contest_list.page")));
const Questions = lazy(retryFailLoad(() => import("views/pages/questions/questions.page")));
const Rankings = lazy(retryFailLoad(() => import("views/pages/rankings/rankings.page")));
const NotFound = lazy(retryFailLoad(() => import("views/pages/not_found/not_found.page")));
const Logout = lazy(retryFailLoad(() => import("views/pages/logout/logout.page")));
const ContestResultListPage = lazy(
	retryFailLoad(() => import("views/pages/contest_result_list/contest_result_list.page"))
);
const ComponentsReview = lazy(
	retryFailLoad(() => import("views/pages/components_review/components_review.page"))
);

export const RootRoutes = (
	<Fragment>
		<Route element={<PrivateRoute />}>
			<Route path="/logout" element={<Logout />} />
			<Route path="/dashboard/" element={<Contests />} />
			<Route path="/contest/result/:contestId" element={<ContestResultListPage />} />
			<Route path="/contest/:id" element={<Questions />} />
			<Route path="/leaderboard" element={<Rankings />} />
			<Route path="/contest/:id/:questionId" element={<Questions />} />
		</Route>

		<Route element={<NotAuthOnlyRoute />}>
			<Route index element={<Contests />} />
			<Route path="/backdoor" element={<Backdoor />} />
		</Route>

		<Route path="components" element={<ComponentsReview />} />
		<Route path="help/*" element={<Help />} />
		<Route path="prizes" element={<Help />} />
		<Route path="premios" element={<Help />} />
		<Route path="*" element={<NotFound />} />
	</Fragment>
);

export default RootRoutes;
