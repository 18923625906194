import {makeAutoObservable, observable, runInAction} from "mobx";
import {injectable, inject} from "inversify";
import type {IAnswer} from "data/types/contests";
import {Bindings} from "data/constants/bindings";
import type {
	IAnswersApiProvider,
	IAnswersPayload,
	IAnswerRequestPayload,
} from "data/providers/api/answers.api.provider";

type IITerribleType = IAnswer;

export interface IAnswersStore {
	get list(): IITerribleType[];

	set list(value: IITerribleType[]);

	get hasChanges(): boolean;

	getByID(questionId: number): IITerribleType | undefined;

	fetch(params: IAnswerRequestPayload): Promise<IITerribleType[]>;

	save(params: Omit<IAnswersPayload, "answers">): Promise<IITerribleType[]>;

	clear(): void;

	add(questionId: number, value: number | string): void;

	has(questionId: number, value: number): boolean;

	remove(questionId: number): void;

	onAnswer(callback: () => void): void;

	offAnswer(callback: () => void): void;
}

@injectable()
export class AnswersStore implements IAnswersStore {
	private _answerCallback: Array<() => void> = [];

	constructor(
		@inject(Bindings.AnswersApiProvider) private _answersProvider: IAnswersApiProvider
	) {
		makeAutoObservable(this);
	}

	@observable private _list: IITerribleType[] = [];

	get list() {
		return this._list;
	}

	set list(list: IITerribleType[]) {
		this._list = list;
	}

	@observable private _hasChanges: boolean = false;

	get hasChanges() {
		return this._hasChanges;
	}

	getByID(id: number): IITerribleType | undefined {
		if (!id) {
			return;
		}
		return this._list.find((it) => it.questionId === id);
	}

	clear() {
		this._list = [];
		this._hasChanges = false;
	}

	add(questionId: number, value: number) {
		this.list = [
			...this.list.filter((answer) => answer.questionId !== questionId),
			{
				questionId,
				value,
			},
		];

		this._hasChanges = true;

		this._answerCallback.forEach((callback) => callback());
	}

	remove(questionId: number) {
		this.list = this.list.filter((answer) => answer.questionId !== questionId);
		this._hasChanges = true;
	}

	has(questionId: number, value: number) {
		return !!this._list.find(
			(answer) => answer.questionId === questionId && answer.value === value
		);
	}

	async fetch(params: IAnswerRequestPayload): Promise<IITerribleType[]> {
		const response = await this._answersProvider.get(params);

		if (response.data.success.answers) {
			runInAction(() => {
				this._list = response.data.success.answers;
			});
		}

		return response.data.success.answers;
	}

	async save(params: Omit<IAnswersPayload, "answers">): Promise<IITerribleType[]> {
		const response = await this._answersProvider.save({
			...params,
			answers: this._list.map((answer) => {
				return {
					questionId: answer.questionId,
					value: answer.value,
				};
			}),
		});

		if (response.data.success.answers) {
			runInAction(() => {
				this._list = response.data.success.answers;
				this._hasChanges = false;
			});
		}

		return response.data.success.answers;
	}

	onAnswer(callback: () => void) {
		this._answerCallback.push(callback);
	}

	offAnswer(callback: () => void) {
		this._answerCallback = this._answerCallback.filter((fn) => fn !== callback);
	}
}
