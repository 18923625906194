import {makeAutoObservable, observable, runInAction, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {injectable, inject} from "inversify";
import {Countdown} from "data/utils";
import {Bindings} from "data/constants/bindings";
import {type IContestStore} from "data/stores/contest/contest.store";

interface ITime {
	days: number;
	hours: number;
	minutes: number;
	seconds: number;
}

interface IParams {
	contestId: number;
	lockDate: string;
}

export interface IContestCountdownController extends ViewController<Partial<IParams>> {
	get timer(): string;
}

@injectable()
export class ContestCountdownController implements IContestCountdownController {
	@observable private _contestId = 0;
	@observable private _time: ITime | null = null;
	private timerDisposer: ReturnType<typeof reaction> | null = null;

	constructor(@inject(Bindings.ContestStore) private _contestsStore: IContestStore) {
		makeAutoObservable(this);
	}

	get contest() {
		return this._contestsStore.getByID(this._contestId);
	}

	get timer(): string {
		if (!this._time) {
			return "-";
		}
		const {
			days: daysTimer,
			hours: hoursTimer,
			minutes: minutesTimer,
			seconds: secondsTimer,
		} = this._time;

		const days = daysTimer.toString().padStart(2, "0");
		const hours = hoursTimer.toString().padStart(2, "0");
		const minutes = minutesTimer.toString().padStart(2, "0");
		const seconds = secondsTimer.toString().padStart(2, "0");

		if (Number(days)) {
			return `${days}D:${hours}H:${minutes}M`;
		}

		return `${hours}H:${minutes}M:${seconds}S`;
	}

	@observable private _lockDate: string = "";

	private get lockDate() {
		return this.contest?.startDate || this._lockDate || "";
	}

	init(param: Partial<IParams>) {
		this._contestId = param?.contestId ?? 0;
		this._lockDate = param?.lockDate ?? "";
		this.timerDisposer = reaction(
			() => this.lockDate,
			() => this.initTimer()
		);
	}

	dispose() {
		this.timerDisposer?.();
	}

	onChange(param: Partial<IParams>) {
		this._contestId = param?.contestId ?? 0;
		this._lockDate = param?.lockDate ?? "";
		this.initTimer();
	}

	private initTimer() {
		if (this.lockDate) {
			const timer = new Countdown(new Date(this.lockDate));

			timer
				.onTick((days, hours, minutes, seconds) => {
					runInAction(() => {
						this._time = {
							days,
							hours,
							minutes,
							seconds,
						};
					});
				})
				.run();
		}
	}
}
