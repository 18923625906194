import type {ComponentType} from "react";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";

type Factory<T> = () => Promise<{
	default: ComponentType<T>;
}>;

export function retryFailLoad<T>(fn: Factory<T>, retriesLeft = 5, interval = 1000): Factory<T> {
	return () =>
		new Promise((resolve, reject) => {
			fn()
				.then(resolve)
				.catch((error: unknown) => {
					setTimeout(() => {
						if (retriesLeft === 1) {
							reject(error);
							return;
						}

						retryFailLoad(fn, retriesLeft - 1, interval)().then(resolve, reject);
					}, interval);
				});
		});
}

export const extractErrorMessage = (error: AxiosError<IApiResponse>) =>
	error.response?.data?.errors?.[0]?.message || error.message;

export const addScript = (src: string) => {
	return new Promise<HTMLScriptElement>((resolve, reject) => {
		const s = document.createElement("script");

		s.setAttribute("src", src);
		s.addEventListener("load", () => {
			resolve(s);
		});
		s.addEventListener("error", reject);

		document.body.appendChild(s);
	});
};

Object.assign(window, {addScript});
export const parseStringToJson = <T extends object>(str: string): T | null => {
	try {
		return JSON.parse(str) as T;
	} catch (e) {
		console.log(e);
		return null;
	}
};
export * from "data/utils/countdown";
export * from "data/utils/socialShare";
