import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import {makeAutoObservable, observable} from "mobx";
import type {IContestStore} from "data/stores/contest/contest.store";
import {ContestStatusEnum, QuestionStatusEnum} from "data/enums";
import type {IDashboardStore} from "data/stores/dashboard/dashboard.store";
import {IDashboard} from "data/types/dashboard";
import {mapValues, sumBy} from "lodash";
import type {IUserStore} from "data/stores/user/user.store";
import {IContest} from "data/types/contests";
import type {IAnswersStore} from "data/stores/answers/answers.store";
import type {IUserStatsStore} from "data/stores/user_stats/user_stats.store";

interface IParams {
	contestId?: number;
}

export interface IGameBarController extends ViewController<IParams | void> {
	i18n: ILocalizationStore;

	get isComplete(): boolean;

	get dashboardStats(): Record<keyof IDashboard, string | number>;

	get userName(): string;

	get contest(): IContest | undefined;

	get potentialPoints(): number;

	get contestPoints(): number | null;

	get contestRank(): number | null;
}

@injectable()
export class GameBarController implements IGameBarController {
	@observable private _contestId = 0;

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.ContestStore) private _contestsStore: IContestStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.DashboardStore) private _dashboardStore: IDashboardStore,
		@inject(Bindings.AnswersStore) private _answersStore: IAnswersStore,
		@inject(Bindings.UserStatsStore) private _userStatsStore: IUserStatsStore
	) {
		makeAutoObservable(this);
	}

	get dashboardStats() {
		return mapValues(this._dashboardStore.stats, (value) => {
			return value === null ? "-" : value;
		});
	}

	get userName() {
		return this._userStore.user?.username ?? "-";
	}

	get isComplete() {
		return this.contest?.status === ContestStatusEnum.COMPLETE;
	}

	get contest() {
		return this._contestsStore.getByID(this._contestId);
	}

	get potentialPoints() {
		const questions = this.contest?.questions || [];

		return sumBy(this._answersStore.list, (answer) => {
			const question = questions.find((question) => question.id === answer.questionId);
			if (question?.status !== QuestionStatusEnum.OPEN && answer.value !== question?.answer) {
				return 0;
			}
			const option = question.options.find((option) => option.id === answer.value);
			return answer.points || option?.points || 0;
		});
	}

	get contestStats() {
		return this._userStatsStore.getByID(this._contestId);
	}

	get contestPoints() {
		return this.contestStats?.points || null;
	}

	get contestRank() {
		return this.contestStats?.rank || null;
	}

	init(param: IParams): void {
		this._contestId = param?.contestId ?? 0;
		void this._dashboardStore.fetch();
		void this._userStatsStore.fetch();
	}

	onChange(param: IParams) {
		this._contestId = param?.contestId ?? 0;
	}
}
